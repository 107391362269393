import {Component, OnInit} from '@angular/core';
import {WalletService} from "../services/WalletService";
import {DoodbeastContract} from "../DoodbeastContract";
// import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import { MintFactory } from 'src/services/MintFactory.service';

const doodbeastJson = require('../../truffle/build/contracts/Doodbeast.json');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  doodbeastContract: DoodbeastContract | undefined;
  colors: string[] | undefined;

  private readonly maxSupply = 5555;
  maxFreeMints = 555;
  price = 0.015; ///eth
  maxPerMint = 15;
  maxPerFreeMint = 4;
  mintAmount = 1;
  totalSupply = 0;
  freeMintSupply = 0;
  freeMintAvailable = false;
  soldOut = false;
  walletConnected = false;
  loading = false;

  constructor(
    private mintFactory: MintFactory,
    private walletService: WalletService) {

  }

  get disableMintButton() {
    return this.totalSupply + this.mintAmount > this.maxSupply;
  }

  ngOnInit(): void {
    this.initSupply();
  }

  increaseMint() {
    const max = this.freeMintAvailable ? this.maxPerFreeMint : this.maxPerMint;
    this.mintAmount = Math.min(max, this.mintAmount + 1);
  }

  decreaseMint() {
    this.mintAmount = Math.max(1, this.mintAmount - 1);
  }

  async initSupply() {
    const provider = new Web3.providers.HttpProvider(
      // 'https://eth-ropsten.alchemyapi.io/v2/8EeY15jy32tuaJl4M8Fx6mWlmnSggvox'
      "https://eth-mainnet.alchemyapi.io/v2/f46UvqKN605uslUs-bulSi_cyVdvet-f"
    );
    const web3 = new Web3(provider);
    const netId = await web3.eth.net.getId();
    const contractAddress = doodbeastJson.networks[netId].address;
    const rawMintContract = new web3.eth.Contract(doodbeastJson.abi, contractAddress);
    const doodbeastContract = this.mintFactory.create(rawMintContract, contractAddress);
    const totalSupply = await doodbeastContract.totalSupply();
    const price = await doodbeastContract.price();
    this.totalSupply = totalSupply;
    this.price = price;
  }

  connectWallet() {
    this.walletService.init()
      .then(doodbeastContract => {
        this.doodbeastContract = doodbeastContract;
        return this.updateTotalSupply();
      })
      .catch(err => {
        console.error(err);
      });
  }

  mintFree() {
    if (this.totalSupply + this.mintAmount <= this.maxFreeMints) {

      this.loading = true;

      this.doodbeastContract?.freeMint(this.mintAmount)
        .then(() => {
          return this.updateTotalSupply();
        })
        .catch(err => {
          this.loading = false;
          console.error(err);
        });
    }
  }

  mint() {
    if (!this.disableMintButton) {
      this.loading = true;

      const total = this.safeMultiple(this.mintAmount, this.price);

      // console.log(`${this.mintAmount} * ${this.price} = ${total}`);

      this.doodbeastContract?.saleMint(this.mintAmount, total)
        .then(() => {
          return this.updateTotalSupply();
        })
        .catch(err => {
          this.loading = false;
          console.error(err);
        });
    }
  }

  updateTotalSupply(): Promise<any> {
    return this.doodbeastContract?.totalSupply()
      .then((totalSupply) => {
        this.walletConnected = true;
        this.totalSupply = totalSupply;
        this.freeMintAvailable = totalSupply < this.maxFreeMints;
        this.soldOut = totalSupply >= this.maxSupply;
        this.mintAmount = 1;
        this.loading = false;
      }) || Promise.resolve();
  }

  private safeMultiple(num1: number, num2: number) {
    var _cf = (function() {
      function _shift(x: any) {
        var parts = x.toString().split('.');
        return (parts.length < 2) ? 1 : Math.pow(10, parts[1].length);
      }
      return function() {
        return Array.prototype.reduce.call(arguments, function (prev, next) { return prev === undefined || next === undefined ? undefined : Math.max(prev as any, _shift (next)); }, -Infinity);
      };
    })();


    (Math as any).m = function () {
      var f = _cf.apply(null, arguments as any);
      function cb(x: any, y: any, i: any, o: any) { return (x*(f as any)) * (y*(f as any)) / ((f as any) * (f as any)); }
      return Array.prototype.reduce.call(arguments, cb, 1);
    };

    return (Math as any).m(num1, num2);
  }
}
