import {Injectable} from "@angular/core";
import {WalletService} from "./WalletService";
import {DoodbeastContract} from "../DoodbeastContract";
import {MintFactory} from "./MintFactory.service";

// @ts-ignore
const doodbeastJson = require('../../truffle/build/contracts/Doodbeast.json');
// @ts-ignore
import Web3 from "web3";

@Injectable({
  providedIn: 'root',
})
export class Metamask implements WalletService {

  constructor(private mintFactory: MintFactory) {
  }

  public async init(): Promise<DoodbeastContract> {
    let errMsg;

    // @ts-ignore
    const ethereum = window.ethereum;
    if (typeof ethereum !== 'undefined') {
      const web3 = new Web3(ethereum);
      await ethereum.enable();
      const netId = await web3.eth.net.getId();
      const chainId = await web3.eth.getChainId();
      console.log({chainId, netId})
      const accounts = await web3.eth.getAccounts();
      const selectedAccount = accounts[0];

      if (typeof selectedAccount !== 'undefined') {
        console.log(selectedAccount);
        const mintContract = this.initMintContract(web3, netId, selectedAccount);
        return Promise.resolve(mintContract);
      } else {
        errMsg = 'Please login with MetaMask and connect the account to this site.';
        alert(errMsg);
        return Promise.reject({msg: errMsg});
      }
    } else {
      errMsg = 'Enable Metamask!';
      alert(errMsg);
      return Promise.reject({msg: errMsg});
    }
  }

  private initMintContract(web3: any, netId: number, selectedAccount: string): DoodbeastContract {
    const rawMintContract = new web3.eth.Contract(doodbeastJson.abi, doodbeastJson.networks[netId].address);
    return this.mintFactory.create(rawMintContract, selectedAccount);
  }
}
